import { useContext, useEffect, useState } from "react";
import Layout from "../../Layouts";
import MasterContext from "../../Services";
import SampleGallery from "../../Assets/Image/Header/header.webp";
import PhotoAlbum from "react-photo-album";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import { useParams } from "react-router-dom";

function GalleryDetailPage(params) {
  const [index, setIndex] = useState(-1);
  const { loading, getAllMaster } = useContext(MasterContext);
  const [data, setData] = useState([]);
  const { id } = useParams();

  const getData = async (id) => {
    const newData = await getAllMaster(
      "public/gallery?m_gallery_categories_id=" + id
    );
    if (newData) {
      setData(
        newData?.map((item) => ({
          src: process.env?.REACT_APP_FILE_URL + item?.photo_file,
          width: 1000,
          height: 1000,
          alt: item?.name,
        }))
      );
    }
  };

  useEffect(() => {
    window.scroll(0, 0);
    getData(id);
  }, [id]);

  return (
    <Layout>
      <div class="first-widget parallax" id="blog">
        <div class="parallax-overlay">
          <div class="container pageTitle">
            <div class="row">
              <div class="col-md-6 col-sm-6">
                <h2 class="page-title">Galeri</h2>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container my-5">
        <PhotoAlbum
          layout="columns"
          photos={data}
          onClick={({ index }) => setIndex(index)}
        />
        <Lightbox
          slides={data}
          open={index >= 0}
          index={index}
          close={() => setIndex(-1)}
          // enable optional lightbox plugins
          plugins={[Fullscreen, Slideshow, Thumbnails, Zoom]}
        />
      </div>
    </Layout>
  );
}

export default GalleryDetailPage;
