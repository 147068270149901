import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import ServiceState from "../Services/state.js";
import HomePage from "../Pages/Home";
import ProfilePage from "../Pages/Profile/index.js";
import NewsPage from "../Pages/News/index.js";
import AgendaPage from "../Pages/Agenda/index.js";
import ChapterPage from "../Pages/Chapter/index.js";
import MemberPage from "../Pages/Chapter/member.js";
import GalleryPage from "../Pages/Gallery/index.js";
import ContactUsPage from "../Pages/ContactUs/index.js";
import ContactPage from "../Pages/Contact/index.js";
import RegistrationPage from "../Pages/Registration/index.js";
import DetailNewsPage from "../Pages/News/detail.js";
import GalleryDetailPage from "../Pages/Gallery/detail.js";

const AppRoutes = () => {
  return (
    <ServiceState>
      <BrowserRouter>
        <Routes>
          <Route>
            <Route exact path="/" element={<HomePage />} />
            <Route path="/profil" element={<ProfilePage />} />
            <Route path="/berita" element={<NewsPage />} />
            <Route path="/berita/detail/:id" element={<DetailNewsPage />} />
            <Route path="/agenda" element={<AgendaPage />} />
            <Route path="/chapter" element={<ChapterPage />} />
            <Route path="/chapter/detail/:id" element={<MemberPage />} />
            <Route path="/galeri" element={<GalleryPage />} />
            <Route path="/galeri/detail/:id" element={<GalleryDetailPage />} />
            <Route path="/hubungi-kami" element={<ContactUsPage />} />
            <Route path="/kontak" element={<ContactPage />} />
            <Route path="/registrasi" element={<RegistrationPage />} />
          </Route>
        </Routes>
      </BrowserRouter>
      <ToastContainer />
    </ServiceState>
  );
};

export default AppRoutes;
