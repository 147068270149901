import { useState } from "react";
import { Link } from "react-router-dom";
import LogoImg from "../../Assets/Image/logo.png";

function Header(props) {
  const [isMobile, setIsmobile] = useState(false);

  return (
    <>
      {/* <div class="responsive_menu">
        <ul class="main_menu">
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <a href="#">Profil</a>
            <ul>
              <li>
                <a href="portfolio.html">Portfolio Grid</a>
              </li>
              <li>
                <a href="project-image.html">Project Image</a>
              </li>
              <li>
                <a href="project-slideshow.html">Project Slideshow</a>
              </li>
            </ul>
          </li>
          <li>
            <a href="#">Blog</a>
            <ul>
              <li>
                <a href="blog.html">Blog Standard</a>
              </li>
              <li>
                <a href="blog-single.html">Blog Single</a>
              </li>
              <li>
                <a href="#">visit templatemo</a>
              </li>
            </ul>
          </li>
          <li>
            <a href="archives.html">Archives</a>
          </li>
          <li>
            <a href="contact.html">Contact</a>
          </li>
        </ul>
      </div> */}

      <header class="site-header clearfix">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-md-12 d-flex align-items-center justify-content-between">
              <div class="pull-left logo">
                <Link to="/">
                  <img
                    src={LogoImg}
                    className="logo-header"
                    alt="Medigo by templatemo"
                  />
                </Link>
              </div>

              <div class="main-navigation pull-right">
                <nav class="main-nav visible-md visible-lg">
                  <ul class="sf-menu">
                    <li class="active">
                      <Link to="/">Beranda</Link>
                    </li>
                    <li>
                      <Link to="/profil">Profil</Link>
                      {/* <ul>
                        <li>
                          <Link to="/profil/tentang-viori">Tentang VIORI</Link>
                        </li>
                        <li>
                          <Link to="/profil/visi-misi">Visi Misi</Link>
                        </li>
                        <li>
                          <Link to="/profil/susunan-pengurus">Susunan Pengurus</Link>
                        </li>
                      </ul> */}
                    </li>
                    <li>
                      <Link to="/berita">Berita</Link>
                    </li>
                    <li>
                      <Link to="/agenda">Agenda</Link>
                    </li>
                    <li>
                      <Link to="/chapter">Chapter</Link>
                    </li>
                    <li>
                      <Link to="/galeri">Galeri</Link>
                      {/* <ul>
                        <li>
                          <Link to="/informasi/daftar-member">Cara Menjadi Member</Link>
                        </li>
                        <li>
                          <Link to="/informasi/daftar-chapter">Cara Membuat Chapter Baru</Link>
                        </li>
                      </ul> */}
                    </li>
                    <li>
                      <Link to="/hubungi-kami">Hubungi Kami</Link>
                    </li>
                  </ul>
                </nav>

                <div class="responsive-navigation visible-sm visible-xs">
                  <a href="#nogo" class="menu-toggle-btn">
                    <i class="fa fa-bars"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}

export default Header;
