import Layout from "../../Layouts";
import { useContext, useEffect, useState } from "react";
import MasterContext from "../../Services";
import { Link } from "react-router-dom";
import moment from "moment/moment";
import "moment/locale/id";
moment.locale("id");

function NewsPage(params) {
  const { loading, getAllMaster } = useContext(MasterContext);
  const [data, setData] = useState([]);

  const getData = async () => {
    const newData = await getAllMaster("public/news");
    if (newData) {
      setData(newData);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <Layout>
      <div class="first-widget parallax" id="blog">
        <div class="parallax-overlay">
          <div class="container pageTitle">
            <div class="row">
              <div class="col-md-6 col-sm-6">
                <h2 class="page-title">Berita</h2>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container">
        <div class="blog-posts">
          <div class="row">
            {data?.map((item, index) => (
              <div class="col-md-4">
                <div class="post-blog">
                  <div class="blog-image">
                    <Link to={"detail/" + item?.slug}>
                      <img
                        src={process.env?.REACT_APP_FILE_URL + item?.photo_file}
                        alt=""
                      />
                    </Link>
                  </div>
                  <div class="blog-content">
                    <span class="meta-date">
                      {moment(item?.created_at)?.format("LLL")}
                    </span>
                    <span class="meta-author">{item?.user?.name}</span>
                    <h3>
                      <Link to={"detail/" + item?.slug}>{item?.name}</Link>
                    </h3>
                    <p>
                      {item?.short_description}{" "}
                      <Link to={"detail/" + item?.slug} className="text-primary">
                        Continue Reading...
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default NewsPage;
