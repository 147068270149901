import { useContext, useEffect, useState } from "react";
import Layout from "../../Layouts";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Table } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import MasterContext from "../../Services";

function MemberPage(params) {
  const { loading, getAllMaster } = useContext(MasterContext);
  const [data, setData] = useState([]);
  const [dataChapter, setDataChapter] = useState([]);
  const { id } = useParams();

  const getData = async () => {
    const newData = await getAllMaster("public/member/" + id);
    if (newData) {
      setData(newData);
    }
  };

  const getDataChapter = async () => {
    const newData = await getAllMaster("public/chapter/" + id);
    if (newData) {
      setDataChapter(newData);
    }
  };

  useEffect(() => {
    getDataChapter();
    getData();
  }, []);

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <Layout>
      <div class="first-widget parallax" id="blog">
        <div class="parallax-overlay">
          <div class="container pageTitle">
            <div class="row">
              <div class="col-md-6 col-sm-6">
                <h2 class="page-title">Chapter - Member</h2>
              </div>
              <div class="col-md-6 col-sm-6 text-right">
                <Link class="page-title text-primary" to={"/chapter"}>
                  --Back--
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container mt-5">
        <h3 className="fw-bold text-center mb-3">
          Daftar Member VIORI Chapter {dataChapter?.name}
        </h3>
        <Table bordered hover>
          <thead>
            <tr>
              <th style={{ width: "15%" }} className="text-center">
                NRA
              </th>
              <th className="text-center">Nama Anggota</th>
              <th className="text-center">Checklist Kilometer</th>
            </tr>
          </thead>
          <tbody>
            {data?.map((item, index) => (
              <tr key={index}>
                <td className="align-middle">
                  {"V-" + String(item?.member_number).padStart(3, "0")}
                </td>
                <td className="align-middle">{item?.name}</td>
                <td className="align-middle text-center">
                  {item?.checklist_kilometer ?? "-"}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </Layout>
  );
}

export default MemberPage;
