import React from "react";
import { FiMail } from "react-icons/fi";
import { AiOutlineInstagram, AiOutlineTikTok } from "react-icons/ai";

const Sosmed = ({ darkTheme }) => {
  return (
    <div className="flex justify-center items-center text-4xl gap-4 max-w-sm mx-auto text-slate-500 dark:text-slate-400">
      <a
        href="https://www.instagram.com/vario160ridersindonesia/"
        className={`hover:scale-105 active:scale-95 transition ${
          darkTheme
            ? "hover:text-slate-200 active:text-white"
            : "hover:text-slate-600 active:text-slate-900"
        }`}
      >
        {" "}
        <AiOutlineInstagram />{" "}
      </a>
      <a
        href="https://www.tiktok.com/@vario160ridersindonesia/"
        className={`hover:scale-105 active:scale-95 transition ${
          darkTheme
            ? "hover:text-slate-200 active:text-white"
            : "hover:text-slate-600 active:text-slate-900"
        }`}
      >
        {" "}
        <AiOutlineTikTok />{" "}
      </a>
      <a
        href="mailto:vario160ridersindonesia@gmail.com"
        className={`hover:scale-105 active:scale-95 transition ${
          darkTheme
            ? "hover:text-slate-200 active:text-white"
            : "hover:text-slate-600 active:text-slate-900"
        }`}
      >
        {" "}
        <FiMail />{" "}
      </a>
    </div>
  );
};

export default Sosmed;
