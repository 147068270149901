import Layout from "../../Layouts";
import { useContext, useEffect, useState } from "react";
import MasterContext from "../../Services";
import { Link, useParams } from "react-router-dom";
import parse from "html-react-parser";
import moment from "moment/moment";
import "moment/locale/id";
import {
  FacebookIcon,
  FacebookShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
moment.locale("id");

function DetailNewsPage(params) {
  const { loading, getAllMaster } = useContext(MasterContext);
  const [data, setData] = useState({});
  const [dataRecent, setDataRecent] = useState([]);
  const { id } = useParams();

  const getDataRecent = async () => {
    const newData = await getAllMaster("public/news-recent");
    if (newData) {
      setDataRecent(newData);
    }
  };

  const getData = async (id) => {
    const newData = await getAllMaster("public/news-slug/" + id);
    if (newData) {
      setData(newData);
    }
  };

  useEffect(() => {
    window.scroll(0, 0);
    getData(id);
    getDataRecent();
  }, [id]);

  return (
    <Layout>
      <div class="first-widget parallax" id="blog">
        <div class="parallax-overlay">
          <div class="container pageTitle">
            <div class="row">
              <div class="col-md-6 col-sm-6">
                <h2 class="page-title">Berita</h2>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container">
        <div class="row">
          <div class="col-md-8 blog-posts">
            <div class="row">
              <div class="col-md-12">
                <div class="post-blog">
                  <div class="blog-image">
                    <Link to={"/berita/detail/" + data?.slug}>
                      <img
                        src={process.env?.REACT_APP_FILE_URL + data?.photo_file}
                        alt=""
                      />
                    </Link>
                  </div>
                  <div class="blog-content">
                    <div className="d-flex align-items-center">
                      <span class="meta-date">
                        {moment(data?.created_at)?.format("LLL")}
                      </span>
                      <span class="meta-author">{data?.user?.name}</span>
                      <div className="d-flex">
                        <FacebookShareButton
                          url={window.location.href}
                          className="me-2"
                        >
                          <FacebookIcon size={32} round />
                        </FacebookShareButton>
                        <TwitterShareButton
                          url={window.location.href}
                          className="me-2"
                        >
                          <TwitterIcon size={32} round />
                        </TwitterShareButton>
                        <TelegramShareButton
                          url={window.location.href}
                          className="me-2"
                        >
                          <TelegramIcon size={32} round />
                        </TelegramShareButton>
                        <WhatsappShareButton
                          url={window.location.href}
                          className="me-2"
                        >
                          <WhatsappIcon size={32} round />
                        </WhatsappShareButton>
                      </div>
                    </div>
                    <h3>
                      <Link to={"/berita/detail/" + data?.slug}>
                        {data?.title}
                      </Link>
                    </h3>
                    <p className="news-desc">
                      {parse(data?.description || "")}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-4">
            <div class="sidebar">
              <div class="sidebar-widget">
                <h5 class="widget-title">Recent Posts</h5>
                {dataRecent?.map((item, index) => (
                  <div class="last-post clearfix">
                    <div class="thumb pull-left">
                      <Link to={"/berita/detail/" + item?.slug}>
                        <img
                          src={
                            process.env?.REACT_APP_FILE_URL + item?.photo_file
                          }
                          alt=""
                        />
                      </Link>
                    </div>
                    <div class="content">
                      <span>{moment(item?.created_at).format("LLL")}</span>
                      <h4>
                        <Link to={"/berita/detail/" + item?.slug}>
                          {item?.name}
                        </Link>
                      </h4>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default DetailNewsPage;
