import { Link } from "react-router-dom";
import Layout from "../../Layouts";
import { useContext, useEffect, useState } from "react";
import MasterContext from "../../Services";
import moment from "moment/moment";
import "moment/locale/id";
moment.locale("id");

function HomePage(params) {
  const { loading, getAllMaster } = useContext(MasterContext);
  const [dataRecent, setDataRecent] = useState([]);

  const getDataRecent = async () => {
    const newData = await getAllMaster("public/news-recent");
    if (newData) {
      setDataRecent(newData);
    }
  };

  useEffect(() => {
    getDataRecent();
    window.scroll(0, 0);
  }, []);

  return (
    <Layout>
      <section id="homeIntro" class="parallax first-widget">
        <div class="parallax-overlay">
          <div class="container home-intro-content">
            <div class="row">
              <div class="col-md-12">
                <h2>VARIO 160 RIDERS INDONESIA</h2>
                <h3 className="text-white fs-4 fw-bold">(AHU-0007292.AH.01.07.TAHUN 2024)</h3>
                <p>SATU VISI, SATU MISI, SATU TUJUAN</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="cta clearfix">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <h4 class="cta-title">
                <strong>Mau join member?</strong> klik menu "Informasi" atau
                tekan tombol ini:
              </h4>
              <Link to="registrasi" class="main-button accent-color">
                Join Member
              </Link>
            </div>
          </div>
        </div>
      </section>

      <section class="light-content services">
        <div class="container">
          <div class="row">
            <div class="col-md-4 col-sm-4">
              <div class="service-box-wrap">
                <div class="service-icon-wrap">
                  <i class="fa fa-umbrella fa-2x"></i>
                </div>
                <div class="service-cnt-wrap">
                  <h3 class="service-title">VISI</h3>
                  <p>
                    Menciptakan komunitas motor yang berkarakter, menjunjung
                    tinggi nilai-nilai kekeluargaan dan kebersamaan.
                  </p>
                </div>
              </div>
            </div>

            <div class="col-md-4 col-sm-4">
              <div class="service-box-wrap">
                <div class="service-icon-wrap">
                  <i class="fa fa-mobile-phone fa-2x"></i>
                </div>
                <div class="service-cnt-wrap">
                  <h3 class="service-title">Misi</h3>
                  <p>
                    Menjalin kebersamaan antar komunitas motor, meningkatkan
                    kualitas dan memperluas jaringan, serta peduli terhadap
                    lingkungan sekitar.
                  </p>
                </div>
              </div>
            </div>

            <div class="col-md-4 col-sm-4">
              <div class="service-box-wrap">
                <div class="service-icon-wrap">
                  <i class="fa fa-pencil-square-o fa-2x"></i>
                </div>
                <div class="service-cnt-wrap">
                  <h3 class="service-title">Tujuan</h3>
                  <p>
                    Mencapai visi dan misi melalui kegiatan-kegiatan positif
                    yang menginspirasi dan memberikan manfaat bagi masyarakat.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="blogPosts" class="parallax">
        <div class="parallax-overlay">
          <div class="container">
            <div class="row">
              <div class="col-md-12">
                <div class="section-header">
                  <h2 class="section-title">Berita Terbaru</h2>
                  <p class="section-desc">
                    Dapatkan informasi berita terbaru di VIORI
                  </p>
                </div>
              </div>
            </div>
            <div class="row latest-posts">
              {dataRecent?.map((item, index) => (
                <div class="col-md-4 col-sm-6">
                  <div class="blog-post clearfix">
                    <div class="thumb-post">
                      <Link to={"/berita/detail/"+item?.slug}>
                        <img
                          src={
                            process.env?.REACT_APP_FILE_URL + item?.photo_file
                          }
                          alt=""
                          class="img-circle my-auto"
                        />
                      </Link>
                    </div>
                    <div class="blog-post-content">
                      <h4 class="post-title">
                        <Link to={"/berita/detail/" + item?.slug}>
                          {item?.name}
                        </Link>
                      </h4>
                      <span class="meta-post-date">
                        {moment(item?.created_at).format("LLL")}
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}

export default HomePage;
