import { useCallback, useContext, useEffect, useState } from "react";
import Layout from "../../Layouts";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import MasterContext from "../../Services";
import "react-big-calendar/lib/css/react-big-calendar.css";

const localizer = momentLocalizer(moment);

function AgendaPage(params) {
  const { loading, getAllMaster } = useContext(MasterContext);
  const [data, setData] = useState([]);

  const getData = async () => {
    const newData = await getAllMaster("public/agenda");
    if (newData) {
      setData(
        newData?.map((item, index) => ({
          id: index,
          title: item?.name,
          description: item?.description,
          // allDay: true,
          start: moment(item?.start),
          end: moment(item?.end),
        }))
      );
    }
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const handleSelectEvent = useCallback(
    (event) => window.alert(event.title+" - "+event.description),
    []
  );

  return (
    <Layout>
      <div class="first-widget parallax" id="blog">
        <div class="parallax-overlay">
          <div class="container pageTitle">
            <div class="row">
              <div class="col-md-6 col-sm-6">
                <h2 class="page-title">Agenda</h2>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container mt-5">
        <Calendar
          localizer={localizer}
          events={data}
          startAccessor="start"
          endAccessor="end"
          style={{ height: 500 }}
          onSelectEvent={handleSelectEvent}
        />
      </div>
    </Layout>
  );
}

export default AgendaPage;
