import { useContext, useEffect, useState } from "react";
import Layout from "../../Layouts";
import MasterContext from "../../Services";
import SampleGallery from "../../Assets/Image/Header/header.webp";
import PhotoAlbum from "react-photo-album";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import { Link } from "react-router-dom";

function CategoryGalleryPage(params) {
  const { loading, getAllMaster } = useContext(MasterContext);
  const [data, setData] = useState([]);

  const getData = async () => {
    const newData = await getAllMaster("public/gallery-category");
    if (newData) {
      setData(newData);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <Layout>
      <div class="first-widget parallax" id="blog">
        <div class="parallax-overlay">
          <div class="container pageTitle">
            <div class="row">
              <div class="col-md-6 col-sm-6">
                <h2 class="page-title">Galeri</h2>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container my-5">
        <div class="row">
          {data?.map((item, index) => (
            <div class="col-md-4" key={index}>
              <div class="post-blog category text-center">
                <div class="blog-image category text-center">
                  <Link to={"/galeri/detail/" + item?.id}>
                    <img
                      src={process.env?.REACT_APP_FILE_URL + item?.photo_file}
                      alt=""
                      className="mx-auto"
                    />
                  </Link>
                </div>
                <div class="blog-content text-center">
                  <h3>
                    <Link to={"/galeri/detail/" + item?.id}>{item?.name}</Link>
                  </h3>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Layout>
  );
}

export default CategoryGalleryPage;
