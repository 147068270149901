import { useEffect, useState } from "react";
import Layout from "../../Layouts";

function ContactUsPage(params) {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <Layout>
      <div class="first-widget parallax" id="blog">
        <div class="parallax-overlay">
          <div class="container pageTitle">
            <div class="row">
              <div class="col-md-6 col-sm-6">
                <h2 class="page-title">Hubungi Kami</h2>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container my-5">
        <div class="contact-wrapper">
          <h3>Get In Touch With Us</h3>
          <p>
            Ingin mendapatkan informasi terkait VIORI? atau bingung cara membuat
            chapter baru? atau bingung cara ingin bergabung? Hubungi kami
            melalui form dibawah ini!
          </p>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="contact-form">
              <h3>Kirim Pesan</h3>
              <div class="widget-inner">
                <form action="#" method="post" id="contact-form">
                  <div class="row">
                    <div class="col-md-4">
                      <p>
                        <label for="name">Nama Lengkap:</label>
                        <input type="text" name="name" id="name" />
                      </p>
                    </div>
                    <div class="col-md-4">
                      <p>
                        <label for="email">Nomor WA:</label>
                        <input type="text" name="email" id="email" />
                      </p>
                    </div>
                    <div class="col-md-4">
                      <p>
                        <label for="subject">Judul:</label>
                        <input type="text" name="subject" id="subject" />
                      </p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <p>
                        <label for="message">Isi Pesan:</label>
                        <textarea name="message" id="message"></textarea>
                      </p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <button class="mainBtn" id="submit">
                        Kirim
                      </button>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <div id="result"></div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default ContactUsPage;
