import { Link } from "react-router-dom";

function Footer() {
  return (
    <footer class="site-footer">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <nav class="footer-nav clearfix">
              <ul class="footer-menu">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/profil">Profil</Link>
                </li>
                <li>
                  <Link to="/berita">Berita</Link>
                </li>
                <li>
                  <Link to="/chapter">Chapter</Link>
                </li>
                <li>
                  <Link to="/hubungi-kami">Hubungi Kami</Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <p class="copyright-text">
              Copyright &copy; 2024 VIORI | Vario 160 Riders Indonesia
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
