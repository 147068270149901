import Header from "./Components/Header";
import Footer from "./Components/Footer";

function Layout(props) {
  return (
    <body>
      <Header />

      {props?.children}

      <Footer />
    </body>
  );
}

export default Layout;
