import React from "react";
import LogoPNG from "../../Assets/Image/logo.png";

const Profile = ({darkTheme}) => {
  return (
    <div className="grid max-w-sm gap-2 mx-auto -mt-16 text-center">
      <section className="grid justify-center">
        <img src={LogoPNG} alt="Foto Profil" className="rounded-full h-28" />
      </section>
      <section className="mx-auto align-middle">
        <p className={`mt-4 text-xl font-bold text-slate-900 ${darkTheme? "text-white": "text-black"}`}>
          <div>Vario 160 Riders Indonesia</div>
          <div className="pl-2 text-2xl text-red-700">
            (VIORI)
          </div>
        </p>
      </section>
      <section className="text-sm">
        <p className="">Based in Indonesia</p>
        <p>
          <span> </span> Komunitas Pengguna Motor Honda Vario 160 Series.
        </p>
      </section>
      <section className=""></section>
    </div>
  );
};

export default Profile;
