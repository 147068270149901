import React, { useState, useEffect } from "react";
import Profile from "../../Components/Contact/Profile";
import Sosmed from "../../Components/Contact/Sosmed";
import Button from "../../Components/Contact/Button";
import Footer from "../../Components/Contact/Footer";
import Header from "../../Components/Contact/Header";

const ContactPage = () => {
  const [darkTheme, setDarkTheme] = useState(true);
  const [isSticky, setIsSticky] = useState(false);
  const [contentHeight, setContentHeight] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      const windowHeight = window.innerHeight;

      const documentHeight = document.documentElement.scrollHeight;

      setIsSticky(windowHeight < 580 && documentHeight > windowHeight);

      setContentHeight(windowHeight);
    };

    handleResize(); // Set initial values
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div
      className={`${isSticky ? "overflow-hidden" : ""} ${
        darkTheme ? "dark" : ""
      }`}
    >
      <div
        className={`${
          contentHeight > 580 ? "h-screen" : "h-full"
        } flex justify-center font-poppins ${
          darkTheme ? "bg-slate-800 text-slate-400" : "bg-white text-slate-500"
        }`}
      >
        <div>
          <Header darkTheme={darkTheme} setDarkTheme={setDarkTheme} />
          <Profile darkTheme={darkTheme} />
          <Sosmed darkTheme={darkTheme} />
          <Button darkTheme={darkTheme} />
          <Footer isSticky={isSticky} />
        </div>
      </div>
    </div>
  );
};

export default ContactPage;
