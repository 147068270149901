import React from "react";
import { FaMapSigns, FaWhatsapp } from "react-icons/fa";

const Button = ({ darkTheme }) => {
  return (
    <div className="max-w-sm mx-auto grid justify-center gap-4 my-5 font-bold">
      <a
        target="_blank"
        rel="noreferrer"
        href="https://wa.me/6289602281920"
        className={`flex items-center rounded-2xl text-center border-red-800 border-2 py-3 px-6 shadow-md shadow-red-100 hover:scale-105 active:scale-95 transition ${
          darkTheme
            ? "text-white bg-slate-700 hover:bg-slate-700 active:bg-slate-900"
            : "text-slate-900 bg-slate-200 hover:bg-slate-100 active:bg-slate-200"
        }`}
      >
        <FaWhatsapp size={"2em"} className="me-2" />
        Bro Eric #005 - Humas Nasional 1{" "}
      </a>
      <a
        target="_blank"
        rel="noreferrer"
        href="https://wa.me/6287884052771"
        className={`flex items-center rounded-2xl text-center border-red-800 border-2 py-3 px-6 shadow-md shadow-red-100 hover:scale-105 active:scale-95 transition ${
          darkTheme
            ? "text-white bg-slate-700 hover:bg-slate-700 active:bg-slate-900"
            : "text-slate-900 bg-slate-200 hover:bg-slate-100 active:bg-slate-200"
        }`}
      >
        <FaWhatsapp size={"2em"} className="me-2" />
        Bro Hans #006 - Humas Nasional 2{" "}
      </a>
      <a
        target="_blank"
        rel="noreferrer"
        href="https://wa.me/62895338999867"
        className={`flex items-center rounded-2xl text-center border-red-800 border-2 py-3 px-6 shadow-md shadow-red-100 hover:scale-105 active:scale-95 transition ${
          darkTheme
            ? "text-white bg-slate-700 hover:bg-slate-700 active:bg-slate-900"
            : "text-slate-900 bg-slate-200 hover:bg-slate-100 active:bg-slate-200"
        }`}
      >
        <FaWhatsapp size={"2em"} className="me-2" />
        Bro Huda #012 - Humas Nasional 3{" "}
      </a>
      <a
        target="_blank"
        rel="noreferrer"
        href="https://maps.app.goo.gl/hA5qPbVoiL3Qfc1s8"
        className={`flex items-center rounded-2xl text-center border-red-800 border-2 py-3 px-6 shadow-md shadow-red-100 hover:scale-105 active:scale-95 transition ${
          darkTheme
            ? "text-white bg-slate-700 hover:bg-slate-700 active:bg-slate-900"
            : "text-slate-900 bg-slate-200 hover:bg-slate-100 active:bg-slate-200"
        }`}
      >
        <FaMapSigns size={"2em"} className="me-2" />
        Lokasi Sekretariat VIORI
      </a>
    </div>
  );
};

export default Button;
