import { useContext, useEffect, useState } from "react";
import Layout from "../../Layouts";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import MasterContext from "../../Services";

function ChapterPage(params) {
  const { loading, getAllMaster } = useContext(MasterContext);
  const [data, setData] = useState([]);

  const getData = async () => {
    const newData = await getAllMaster("public/chapter");
    if (newData) {
      setData(newData);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <Layout>
      <div class="first-widget parallax" id="blog">
        <div class="parallax-overlay">
          <div class="container pageTitle">
            <div class="row">
              <div class="col-md-6 col-sm-6">
                <h2 class="page-title">Chapter</h2>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container mt-5">
        <h3 className="fw-bold text-center mb-3">
          Daftar Chapter Vario 160 Riders Indonesia
        </h3>
        <Table bordered hover>
          <thead className="text-center">
            <tr>
              <th>Nama Chapter</th>
              <th>Nama PIC / Humas</th>
              <th>Nomor PIC / Humas</th>
              <th>Alamat Sekretariat</th>
              <th>Jumlah Anggota</th>
              <th>Data Lainnya</th>
              <th>Tempat Kopdar</th>
              <th>Detail Anggota</th>
            </tr>
          </thead>
          <tbody className="text-center">
            {data?.map((item, index) => (
              <tr key={index}>
                <td className="align-middle">{item?.name}</td>
                <td className="align-middle">{item?.pic_name}</td>
                <td className="align-middle">{item?.pic_phone_number}</td>
                <td className="align-middle">{item?.pic_address}</td>
                <td className="align-middle text-center">
                  {item?.member_count ?? "-"}
                </td>
                <td className="align-middle text-center d-flex flex-column gap-1">
                  <a
                    className="text-primary"
                    href={process.env.REACT_APP_FILE_URL + "" + item?.logo_file}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Logo
                  </a>
                  <a
                    className="text-primary"
                    href={process.env.REACT_APP_FILE_URL + "" + item?.logo_file}
                    target="_blank"
                    rel="noreferrer"
                  >
                    AD/ART
                  </a>
                  <a
                    className="text-primary"
                    href={process.env.REACT_APP_FILE_URL + "" + item?.logo_file}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Sertifikat
                  </a>
                </td>
                <td className="align-middle">
                  <a
                    className="text-primary"
                    href={"/"}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Klik Disini
                  </a>
                </td>
                <td className="align-middle">
                  <Link
                    to={"/chapter/detail/" + item?.id}
                    className="text-primary"
                  >
                    Klik Sini
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </Layout>
  );
}

export default ChapterPage;
